import { Fragment, useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import SecondConnect from "../assets/background/SecondConnect.png"
import Loading from "../components/Loading"
import "./cloner.css"
import "./ClonerDiscord.sass"
import Select from "react-select"
import DefaultLogo from "../assets/default-logo.jpg"
import Popup from "../components/popup"

const ClonerDiscord = () => {
	const [isBtn, setIsBtn] = useState(true)
	const [token, setToken] = useState(null)
	const [selectedGuild, setSelectedGuild] = useState(null)
	const [guilds, setGuilds] = useState({})
	const [status, setStatus] = useState(false)
	const [error, setError] = useState(false)

	const tokenRef = useRef(null)

	const handleInput = () => {
		setToken(tokenRef.current.value)
	}

	const handleSelect = e => {
		setSelectedGuild(e.value)
	}

	useEffect(() => {
		if (!token && document.getElementById("token")) {
			const fetchData = async () => {
				const res = await (
					await fetch(`${process.env.REACT_APP_API_URL}/fetchToken`, {
						method: "POST",
						redirect: "follow",
						credentials: "include",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
						},
					})
				).json()

				if (res.token.length > 0) {
					setToken(res.token)
					document.getElementById("token").value = res.token
				}
			}

			fetchData().catch(console.error)
		}
	}, [])

	const submit = e => {
		e.preventDefault()
		setIsBtn(false)
		setStatus(false)
		setError(false)

		const token = tokenRef.current.value
		if (token && !selectedGuild) {
			setStatus("Pobieranie listy serwerów...")
			fetch(`${process.env.REACT_APP_API_URL}/fetchGuilds`, {
				method: "POST",
				body: JSON.stringify({ token }),
				redirect: "follow",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			})
				.then(res => res.json())
				.then(res => {
					setStatus()
					res.status
						? setGuilds(
								res.data.map(data => {
									return {
										label: data.name,
										value: data.id,
										icon: data.icon,
									}
								})
						  )
						: setError(res.message)
					setIsBtn(true)
				})
				.catch(() => {
					setError("Wystąpił problem podczas łączenia z serwerem.")
					setIsBtn(true)
				})
		} else if (token && selectedGuild) {
			setStatus("Pobieranie emoji... [Czas oczekiwania: kilka sekund]")
			fetch(`${process.env.REACT_APP_API_URL}/fetchEmojis`, {
				method: "POST",
				body: JSON.stringify({ token, guildId: selectedGuild }),
				redirect: "follow",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			})
				.then(res => res.json())
				.then(res => {
					if (!res.status) {
						setStatus("")
						return setError(res.message)
					} else {
						const endpoint = res.url
						window.location.href = `${process.env.REACT_APP_API_URL}${endpoint}`
						setStatus("Pobrano wszystkie emoji!")
					}
					setIsBtn(true)
				})
				.catch(() => {
					setError("Wystąpił problem podczas łączenia z serwerem.")
					setIsBtn(true)
				})
		} else {
			setError("Uzupełnij pola!")
			setIsBtn(true)
		}
	}

	const formatOptionLabel = ({ label, icon, value }) => {
		const avatar = !icon ? DefaultLogo : `https://cdn.discordapp.com/icons/${value}/${icon}.png`

		return (
			<>
				<div className="avatar-server">
					<img src={avatar} alt="avatar-server" />
					{label}
				</div>
			</>
		)
	}

	const onMouseEnter = () => {
		const cloner = document.querySelector(".cloner")
		if (cloner) cloner.style.zIndex = "200"
	}

	const onMouseLeave = () => {
		const cloner = document.querySelector(".cloner")
		if (cloner) cloner.style.zIndex = null
	}

	return (
		<div className="cloner">
			<img className="cloner-background" src={SecondConnect} alt="Background line" />
			<form className="cloner-form">
				<label htmlFor="token" className="input-label">
					Token
					<span className="input-label-help" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
						<FontAwesomeIcon icon={faCircleInfo} />
						<div className="input-label-help-contant">
							<h1 className="input-label-help-title">Po co jest nam potrzebny twój token?</h1>
							<p className="input-label-help-description">
								Twój token potrzebujemy do tego abyśmy mogli pobrać z twojego konta na jakich serwerach jesteś.
							</p>
							<h1 className="input-label-help-title">Skąd uzyskać swój token?</h1>
							<p className="input-label-help-description">
								1. Wchodzimy na Discord z przegladarki (najlepiej Google Chrome) <br />
								2. Nastepnie logujemy sie na nasze konto <br />
								3. Po zalogowaniu na nasze konto klikamy 3 KROPECZKI w prawym gornym rogu <br />
								4. po kliknieciu 3 kropek klikamy "Wiecej narzedzi" oraz "Narzedzia dla deweloperow" <br />
								5. Gdy klinkiemy Narzedzia dla deweloperow klikamy 2 male strzaleczki obok przycisku "Console" <br />
								6. Klikamy strzalke i wybieramy "Application" <br />
								7. Nastepnie klikamy strzalke kolo napisu "Local Storage" <br />
								8. Gdy klikniemy ten przycisk pojawi sie link "https://discord.com/ " - Klikamy go <br />
								9. Nastepnie w "Filter" Wpisujemy "Token" i go kopiujemy oraz wklejamy bez " do klonera <br />
								10. i tyle mamy nasz Token Konta Discord
								<br />
								<br />
								<div className="serwer">Masz problem? Dolacz na nasz Support serwer</div>{" "}
								<a href="https://discord.gg/4sQa8YVZFU">Kliknij aby dolaczyc</a>{" "}
							</p>
						</div>
					</span>
				</label>
				<a href="https://szablonydiscord.pl">Szablony serwerow discord (kliknij)</a>
				<input
					type="text"
					id="token"
					placeholder="Wpisz swój token"
					onChange={handleInput}
					ref={tokenRef}
					disabled={guilds.length > 0}
				/>
				{Object.keys(guilds).length > 0 ? (
					<Select
						isSearchable={true}
						options={guilds}
						formatOptionLabel={formatOptionLabel}
						theme={theme => ({
							...theme,
							borderRadius: 0,
							colors: {
								primary: "#d8b133",
							},
						})}
						className="react-select-container"
						classNamePrefix="react-select"
						onMenuOpen={() => {
							setError("")
							setStatus("")
						}}
						isDisabled={status === "Pobieranie emoji... [Czas oczekiwania: kilka sekund]" ? true : false}
						placeholder="Wybierz serwer..."
						noOptionsMessage={() => "Nie znaleziono takich serwerów"}
						onChange={handleSelect}
					/>
				) : null}
				{status ? <p className="status z-twojamama">{status}</p> : null}
				{error ? <p className="error z-twojamama">Błąd: {error}</p> : null}
				{isBtn && (
					<button className="btn clone" onClick={submit}>
						Dalej
					</button>
				)}
			</form>
		</div>
	)
}

const Wrapper = () => {
	return (
		<>
			<Popup />
			<ClonerDiscord />
			<Loading />
		</>
	)
}

export default Wrapper
