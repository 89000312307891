import { useEffect, useState } from 'react';
import './Loading.sass';

const Loading = () => {
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => document.readyState === "complete" ? setLoaded(true) : null, 1000);
        return () => clearInterval(interval);
    });

    return (
        <div className="loading" style={isLoaded ? {animation: 'fadeOut .5s ease both'} : null}>
            <ul className="bars">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    );
}

export default Loading;
