import { Link } from 'react-router-dom';
import Glide from '@glidejs/glide';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import FirstConnect from '../assets/background/FirstConnect.png';
import SecondConnect from '../assets/background/SecondConnect.png';
import Circle from '../assets/background/Circle.png';
import Triangle from '../assets/background/Triangle.png';
import './Home.sass';
import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Data from '../data.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { HashLink } from 'react-router-hash-link';
import Loading from '../components/Loading';
import Stats from '../components/Stats';

const Home = () => {
  const [stats, setStats] = useState({});

  const fetchStats = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/stats`);
    const data = await res.json();

    setStats(data);
  };

  const GlobeIcon = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='#fad61d'
        className='w-45'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418'
        />
      </svg>
    );
  };

  const ServerIcon = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='#fad61d'
        className='w-45'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M5.25 14.25h13.5m-13.5 0a3 3 0 01-3-3m3 3a3 3 0 100 6h13.5a3 3 0 100-6m-16.5-3a3 3 0 013-3h13.5a3 3 0 013 3m-19.5 0a4.5 4.5 0 01.9-2.7L5.737 5.1a3.375 3.375 0 012.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 01.9 2.7m0 0a3 3 0 01-3 3m0 3h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008zm-3 6h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008z'
        />
      </svg>
    );
  };

  const EmojiIcon = () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='#fad61d'
        className='w-45'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z'
        />
      </svg>
    );
  };

  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      easing: 'ease',
      duration: 400,
      disable: false,
      once: false,
      startEvent: 'DOMContentLoaded',
      throttleDelay: 99,
      debounceDelay: 50,
      disableMutationObserver: false,
    });
    new Glide('.glide', {
      type: 'carousel',
      startAt: 0,
      perView: 4,
      gap: 32,
      autoplay: 3000,
      breakpoints: {
        1280: {
          perView: 3,
        },
        1024: {
          perView: 2,
        },
        768: {
          perView: 1,
        },
      },
    }).mount();

    (async () => {
      if (!localStorage.getItem('uniqueVisit')) {
        await fetch(`${process.env.REACT_APP_API_URL}/addView`, {
          method: 'POST',
        });
        localStorage.setItem('uniqueVisit', 'true')
      }
    })();

    fetchStats();
  }, []);

  return (
    <div className='home'>
      <div className='hero-wrapper' data-aos='fade-right'>
        <p className='hero-text'>Proste Klonowanie</p>
        <p className='hero-text text-white'>Twojego Serwera</p>
        <div className='hero-details'>
          <Link to='ClonerDiscord'>
            <div className='btn red'>Przejdź do Clonera</div>
          </Link>
        </div>
        <div className='hero-bottom'>
          <HashLink
            smooth
            scroll={(el) =>
              el.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
            to='/#home-partners'
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </HashLink>
        </div>
      </div>
      <div className='background-container'>
        <img
          src={FirstConnect}
          alt='Hero background'
          className='home-first_connect'
        />
      </div>
      <div id='home-benefits'>
        <h1 className='heading-secondary' data-aos='zoom-in'>
          Nasze zalety
        </h1>
        <div className='home-benefits-wrapper'>
          {Data.BENEFITS.map((v, i) => (
            <div
              className='home-benefits-benefit'
              data-aos={v.ANIMATION}
              key={i}
            >
              <p className='home-benefits-benefit-icon'>
                <FontAwesomeIcon icon={faIcons[v.ICON] || faQuestion} />
              </p>
              <p className='home-benefits-benefit-title'>{v.TITLE}</p>
              <p className='home-benefits-benefit-description'>
                {v.DESCRIPTION}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div id='home-partners' data-aos='fade-up'>
        <h1 className='heading-primary'>Partnerstwa</h1>
        <div className='home-partners-wrapper'>
          <div className='glide'>
            <div data-glide-el='controls' className='glide_controls'>
              <div data-glide-dir='<' className='glide_controls-left'>
                <FontAwesomeIcon icon={faAngleLeft} />
              </div>
              <div data-glide-dir='>' className='glide_controls-right'>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
            <div data-glide-el='track' className='glide__track'>
              <ul className='glide__slides'>
                {Data.PARTNERS.map((v, key) => (
                  <li className='glide__slide home-partner' key={key}>
                    <img
                      className='home-partner-image'
                      src={v.IMAGE}
                      alt={v.LABEL}
                    />
                    <p className='home-partner-label'>{v.LABEL}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='stats'>
        <div className='stats__container'>
          <Stats title='Wizyt na stronie' num={stats.views ? Math.round((stats.views / 1000) * 100) / 100 : stats.views} Icon={GlobeIcon} visit/>
          <Stats
            title='Sklonowanych serwerów'
            num={stats.cloned_guilds}
            Icon={ServerIcon}
          />
          <Stats
            title='Pobranych folderów emoji'
            num={stats.cloned_emojis}
            Icon={EmojiIcon}
          />
        </div>
      </div>
      <div id='home-opinions' data-aos='fade-up'>
        <h1 className='heading-secondary'>Opinie</h1>
        <div className='home-opinions-wrapper'>
          {Data.OPINIONS.map((v, key) => (
            <div className='home-opinion' key={key}>
              <img
                src={v.AVATAR}
                alt='Discord User'
                className='home-opinion-image'
              />
              <h2 className='home-opinion-heading'>{v.NICKNAME}</h2>
              <p className='home-opinion-content'>{v.DESCRIPTION}</p>
            </div>
          ))}
        </div>
      </div>
      <div className='background-container'>
        <img
          src={SecondConnect}
          alt='Page background'
          className='home-second_connect'
        />
      </div>
      <div id='home-music_bot' data-aos='fade-up'>
        <h1 className='heading-secondary'>Support</h1>
        <a
          href='https://discord.gg/4sQa8YVZFU'
          className='home-music_bot-btn btn red'
          style={{ fontSize: '2em' }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xlink='http://www.w3.org/1999/xlink'
            width='1em'
            height='1em'
            viewBox='0 -64 256 256'
            version='1.1'
            preserveAspectRatio='xMidYMid'
          >
            <g>
              <path
                d='M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z'
                fill='#fff'
                fillRule='nonzero'
              />
            </g>
          </svg>
          Dołącz!
        </a>
      </div>
      <div id='home-about_owner' data-aos='fade-up'>
        <img
          src='https://cdn.discordapp.com/attachments/745050035854508153/984882948736163900/unknown.png'
          alt='Discord User'
          className='home-about_owner-image'
        />
        <div className='home-about_owner-wrapper'>
          <h2 className='home-about_owner-title'>O właścicielu ShizeClone</h2>
          <p className='home-about_owner-owner'>TheProShizer#0001</p>
          <p className='home-about_owner-about'>
            Witaj jestem właścicielem strony ShizeClone. Strona została
            stworzona z myślą o osobach które chcą klonować serwery Discord.
          </p>
        </div>
      </div>
      <div className='background-container'>
        <img src={Triangle} alt='Page background' className='home-triangle' />
        <img src={Circle} alt='Page background' className='home-circle' />
      </div>
      <footer>
        <p>shizeclone.eu &copy; 2022 - Wszelkie prawa zastrzeżone.</p>
      </footer>
    </div>
  );
};

const Wrapper = () => {
  return (
    <>
      <Home />
      <Loading />
    </>
  );
};

export default Wrapper;
