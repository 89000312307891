import Loading from '../components/Loading';
import './Panel.sass';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Panel = () => {
  const [username, setUsername] = useState(null);
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    (async () => {
      const userData = await (
        await fetch(`${process.env.REACT_APP_API_URL}/getUser`, {
          method: 'POST',
          redirect: 'follow',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
      ).json();
      if (!userData.oauthToken || !userData.oauthUser)
        return (window.location = `${process.env.REACT_APP_API_URL}/loginRedirect`);
      setUsername(JSON.parse(userData.oauthUser).username);

      await fetchTickets();
    })();
  }, []);

  const fetchTickets = async () => {
    const ticketsData = await (
      await fetch(`${process.env.REACT_APP_API_URL}/getAllTickets`, {
        method: 'POST',
        redirect: 'follow',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    ).json();
    setTickets(ticketsData.tickets);
  };

  const createTicket = async () => {
    // 1 user - 1 ticket
    // 409 status code jezeli uzytkownik ma juz stwororzony ticket
    await (
      await fetch(`${process.env.REACT_APP_API_URL}/createTicket`, {
        method: 'POST',
        redirect: 'follow',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    ).json();

    await fetchTickets();
  };

  const deleteTicket = async (ticketId) => {
    // 403 - brak permisji
    await fetch(`${process.env.REACT_APP_API_URL}/deleteTicket`, {
      method: 'POST',
      body: JSON.stringify({ ticketId }),
      redirect: 'follow',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    await fetchTickets();
  };

  return (
    <div className='panel__container--outer'>
      <div className='panel__container--inner'>
        <div className='panel__header'>
          <p className='panel__title'>Panel</p>
          <p className='panel__username'>
            Twój nick: <span>{username}</span>
          </p>
        </div>
        <div className='panel__createTicket--container'>
          <button onClick={createTicket} className='panel__createTicket'>
            Stwórz ticket'a
          </button>
        </div>
        <p className='panel__subtext'>(kliknij na ID aby przejść do ticketa)</p>
        <div className='panel__ticketContainer'>
          {tickets.map((t) => (
            <div className='panel__ticket' key={t}>
              <Link to={`/panel/messages?id=${t}`}>
                <p className='panel__id'>ID: {t}</p>
              </Link>
              <div onClick={() => deleteTicket(t)} className='panel__deleteBtn'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='#ff0000'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                  />
                </svg>
              </div>
            </div>
          ))}

          {tickets.length === 0 && (
            <p className='panel__zeroTicketsMessage'>
              Nie znaleziono twojego ticketa
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const Wrapper = () => {
  return (
    <>
      <Panel />
      <Loading />
    </>
  );
};

export default Wrapper;
