import { useState } from "react"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import Logo from "../assets/logo.png"
import "./Header.sass"

const Header = () => {
	const [isHamburgerActive, setHamburgerActive] = useState(false)

	return (
		<header className="header-wrapper">
			<Link className="header-brand" to="/">
				<img src={Logo} alt="logo" className="header-brand-img" />
			</Link>
			<div
				className={`hamburger ${isHamburgerActive ? "active" : ""}`}
				onClick={() => setHamburgerActive(!isHamburgerActive)}>
				<span></span>
				<span></span>
				<span></span>
			</div>
			<nav className={`header-navigation ${isHamburgerActive ? "active" : ""}`}>
				<HashLink
					smooth
					scroll={el => el.scrollIntoView({ behavior: "smooth", block: "center" })}
					to="/#home-music_bot"
					className="header-navigation-option">
					Support
				</HashLink>
				<Link to="/clonerEmoji" className="header-navigation-option">
					ClonerEmoji
				</Link>
				<Link to="/rolesEmojiCloner" className="header-navigation-option">
					RolesEmojiCloner
				</Link>
				<Link to="/emojiChanger" className="header-navigation-option">
					EmojiChanger
				</Link>
				<Link to="/clonerDiscord" className="header-navigation-option btn">
					ClonerDiscord
				</Link>
			</nav>
		</header>
	)
}

export default Header
