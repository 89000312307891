import Loading from '../components/Loading';
import './EmojiChanger.sass';
import { useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { HexColorPicker } from 'react-colorful';
import { ReImg } from 'reimg';

const EmojiChanger = () => {
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [pickerColor, setPickerColor] = useState('#ffcd22');
  const [inputColor, setInputColor] = useState('#ffcd22');
  const [dimensions, setDimensions] = useState([null, null]);
  const [inputCheck, setInputCheck] = useState(false);
  const [openFileSelector, { filesContent, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  useEffect(() => {
    if (step === 2) {
      rerenderCanvas();
    }
  }, [inputCheck, step]);

  const rerenderCanvas = () => {
    const canvas = document.createElement('canvas');
    canvas.width = dimensions[0];
    canvas.height = dimensions[1];
    const ctx = canvas.getContext('2d');

    ctx.fillStyle = /^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i.test(inputColor)
      ? inputColor
      : pickerColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.globalCompositeOperation = 'destination-in';

    const img = new Image();
    img.src = filesContent[0].content;
    console.log(img);

    ctx.drawImage(img, 0, 0);

    const container = document.getElementById('canvas-container');
    while (container.firstChild) {
      container.removeChild(container.lastChild);
    }
    const test = ReImg.fromCanvas(canvas).toImg();
    test.classList.add('canvas-preview');
    container.appendChild(test);

    return canvas;
  };

  const handleEmojiSubmit = () => {
    if (filesContent.length === 0) {
      return setError('Wybierz poprawne zdjęcie, aby przejść dalej');
    }

    if (errors.length !== 0) {
      return setError(error[0]);
    }

    const i = new Image();
    i.src = filesContent[0].content;

    setDimensions([i.width, i.height]);
    setError('');
    setStep(2);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const canvas = rerenderCanvas();
    ReImg.fromCanvas(canvas).downloadPng(filesContent[0].name);

    setError('');
  };

  const handleInputColor = (e) => {
    setInputColor(e);
    if (/^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i.test(e)) {
      setPickerColor(e);
      setInputCheck((prev) => !prev);
    }
  };

  const handlePickerColor = (e) => {
    setPickerColor(e);
    setInputColor(e);
  };

  return (
    <div className='container'>
      <div>
        <h1>Emoji Changer</h1>
      </div>

      {step === 1 ? (
        <div>
          <p>1. Wgraj swoje emoji, aby następnie zmienić ich kolor</p>
          <p>
            <a
              href='https://emoji.gg/category/16/utility'
              className='emoji-link'
              target='_blank'
              rel='noreferrer noopener'
            >
              Tutaj znajdziesz emoji
            </a>
          </p>
          <button onClick={openFileSelector} className='btn btn-m btn-animate'>
            Wgraj emoji
          </button>
          {filesContent[0] && (
            <div className='img-preview-container'>
              <img
                className='image-preview'
                src={filesContent[0].content}
                alt={filesContent[0].name}
              />
              <button
                type='submit'
                className='btn btn-animate'
                onClick={handleEmojiSubmit}
              >
                Przejdź dalej
              </button>
            </div>
          )}
        </div>
      ) : null}

      {step === 2 ? (
        <div>
          <p>2. Wybierz kolor dla swojej nowej emotki</p>
          <form onSubmit={handleFormSubmit}>
            <div className='picker-element'>
              <div>
                <HexColorPicker
                  className='color-picker'
                  color={pickerColor}
                  onChange={handlePickerColor}
                  onMouseUp={rerenderCanvas}
                />
                <input
                  type='text'
                  value={inputColor}
                  onChange={(e) => handleInputColor(e.target.value)}
                />
              </div>
              <div id='canvas-container'></div>
            </div>
            <button type='submit' className='btn btn-m btn-animate'>
              Pobierz emotkę
            </button>
            <button
              type='button'
              className='btn btn-m-1-2'
              onClick={() => setStep(1)}
            >
              Wróć do kroku 1.
            </button>
          </form>
        </div>
      ) : null}
    </div>
  );
};

const Wrapper = () => {
  return (
    <>
      <EmojiChanger />
      <Loading />
    </>
  );
};

export default Wrapper;
