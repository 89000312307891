import { useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import SecondConnect from "../assets/background/SecondConnect.png"
import Loading from "../components/Loading"
import "./cloner.css"
import "./ClonerDiscord.sass"
import Select from "react-select"
import DefaultLogo from "../assets/default-logo.jpg"
import Popup from "../components/popup"

const ClonerDiscord = () => {
	const [isBtn, setIsBtn] = useState(true)
	const [formData, setFormData] = useState({})
	const [guilds, setGuilds] = useState({})
	const [status, setStatus] = useState(false)
	const [error, setError] = useState(false)
	const [isFetchedToken, setFetchedToken] = useState(false)
	const [lengthServer, setLengthServer] = useState(null)

	const tokenRef = useRef(null)

	useEffect(() => {
		if (!isFetchedToken && document.getElementById("token")) {
			fetch(`${process.env.REACT_APP_API_URL}/fetchToken`, {
				method: "POST",
				redirect: "follow",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			})
				.then(res => res.json())
				.then(res => {
					setFetchedToken(true)
					if (res.token.length > 0) {
						setFormData({ ...formData, token: res.token })
						document.getElementById("token").value = res.token
					}
				})
		}
	}, [])

	useEffect(() => {
		if (!lengthServer) return

		if (lengthServer && lengthServer.lengthServer === lengthServer.maxServer) {
			setIsBtn(false)
			setGuilds([])
		} else {
			setIsBtn(true)
		}
	}, [lengthServer])

	const handleInput = e => {
		const data = { ...formData }
		data[tokenRef.current.id] = tokenRef.current.value
		setFormData(data)
	}

	const handleSelect = e => {
		const data = { ...formData }
		data.guild = e.value
		data.icon = e.icon

		setFormData(data)
	}

	const submit = e => {
		e.preventDefault()
		setIsBtn(false)
		setStatus(false)
		setError(false)

		if (formData["token"] && !formData["guild"]) {
			setStatus("Pobierane listy serwerów...")
			fetch(`${process.env.REACT_APP_API_URL}/fetchGuilds`, {
				method: "POST",
				body: JSON.stringify({ token: formData["token"] }),
				redirect: "follow",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			})
				.then(res => res.json())
				.then(res => {
					setStatus()
					res.status
						? setGuilds(
								res.data.map(data => {
									return {
										label: data.name,
										value: data.id,
										icon: data.icon,
										premiumType: data.premiumType,
									}
								})
						  )
						: setError(res.message)
					setIsBtn(true)
					const dataServer = {
						lengthServer: parseInt(res.data && res.data.length),
						maxServer: res.data && res.data[0].premiumType != 0 ? 200 : 100,
					}
					if (res.status) setLengthServer(dataServer)
					console.log(res.status)
				})
				.catch(e => setError("Wystąpił problem podczas łączenia z serwerem."))
		} else if (formData["token"] && formData["guild"]) {
			setStatus("Trwa kopiowanie serwera...")
			fetch(`${process.env.REACT_APP_API_URL}/copyGuild`, {
				method: "POST",
				body: JSON.stringify({
					token: formData["token"],
					guildId: formData["guild"],
				}),
				redirect: "follow",
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			})
				.then(res => res.json())
				.then(res => {
					if (res.message === "Serwer nie może zostać sklonowany") {
						setIsBtn(true)
						setStatus("")
						return setError(res.message)
					}
					if (res.status) {
						setStatus(res.message)
						setError("")
					} else {
						setError(res.message)
						setStatus("")
					}
					if (res.message === "Serwer został sklonowany") {
						setLengthServer(prevState => ({
							...prevState,
							lengthServer: prevState.lengthServer + 1,
						}))
					}
					setIsBtn(true)
				})
				.catch(e => {
					console.log(e)
					setError("Wystąpił błąd! Skontaktuj się z supportem. ")
					setIsBtn(true)
				})
		} else {
			setError("Uzupełnij pola!")
			setIsBtn(true)
		}
	}

	const formatOptionLabel = ({ label, icon, value }) => {
		const avatar = !icon ? DefaultLogo : `https://cdn.discordapp.com/icons/${value}/${icon}.png`

		return (
			<>
				<div className="avatar-server">
					<img src={avatar} alt="avatar-server" />
					{label}
				</div>
			</>
		)
	}

	const onMouseEnter = () => {
		const cloner = document.querySelector(".cloner")
		if (cloner) cloner.style.zIndex = "200"
	}

	const onMouseLeave = () => {
		const cloner = document.querySelector(".cloner")
		if (cloner) cloner.style.zIndex = null
	}

	return (
		<>
			<div className="cloner">
				<img className="cloner-background" src={SecondConnect} alt="Background line" />
				<form className="cloner-form">
					<label htmlFor="token" className="input-label">
						Token
						<span className="input-label-help" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
							<FontAwesomeIcon icon={faCircleInfo} />
							<div className="input-label-help-contant">
								<h1 className="input-label-help-title">Po co jest nam potrzebny twój token?</h1>
								<p className="input-label-help-description">
									Twój token potrzebujemy do tego abyśmy mogli pobrać z twojego konta na jakich serwerach jesteś.
								</p>
								<h1 className="input-label-help-title">Skąd uzyskać swój token?</h1>
								<p className="input-label-help-description">
									1. Wchodzimy na Discord z przegladarki (najlepiej Google Chrome) <br />
									2. Nastepnie logujemy sie na nasze konto <br />
									3. Po zalogowaniu na nasze konto klikamy 3 KROPECZKI w prawym gornym rogu <br />
									4. po kliknieciu 3 kropek klikamy "Wiecej narzedzi" oraz "Narzedzia dla deweloperow" <br />
									5. Gdy klinkiemy Narzedzia dla deweloperow klikamy 2 male strzaleczki obok przycisku "Console" <br />
									6. Klikamy strzalke i wybieramy "Application" <br />
									7. Nastepnie klikamy strzalke kolo napisu "Local Storage" <br />
									8. Gdy klikniemy ten przycisk pojawi sie link "https://discord.com/ " - Klikamy go <br />
									9. Nastepnie w "Filter" Wpisujemy "Token" i go kopiujemy oraz wklejamy bez " do klonera <br />
									10. i tyle mamy nasz Token Konta Discord
									<br />
									<br />
									<div className="serwer">Masz problem? Dolacz na nasz Support serwer</div>{" "}
									<a href="https://discord.gg/4sQa8YVZFU">Kliknij aby dolaczyc</a>
								</p>
							</div>
						</span>
					</label>

					{lengthServer !== null ? (
						<p>
							Jesteś na <span className="max-limit">{lengthServer.lengthServer} serwerach</span>, twój limit to{" "}
							<span className="max-limit">{lengthServer.maxServer} serwerów.</span>
						</p>
					) : (
						<a href="https://szablonydiscord.pl">Szablony serwerow discord (kliknij)</a>
					)}
					<input
						type="text"
						id="token"
						placeholder="Wpisz swój token"
						onChange={handleInput}
						ref={tokenRef}
						disabled={guilds.length > 0}
					/>
					{Object.keys(guilds).length > 0 ? (
						<Select
							isSearchable={true}
							options={guilds}
							formatOptionLabel={formatOptionLabel}
							theme={theme => ({
								...theme,
								borderRadius: 0,
								colors: {
									primary: "#d8b133",
								},
							})}
							className="react-select-container"
							classNamePrefix="react-select"
							placeholder="Wybierz serwer..."
							onMenuOpen={() => {
								setError("")
								setStatus("")
							}}
							isDisabled={status === "Trwa kopiowanie serwera..." ? true : false}
							noOptionsMessage={() => "Nie znaleziono takich serwerów"}
							onChange={handleSelect}
						/>
					) : null}
					{status ? <p className="status">{status}</p> : null}
					{error ? <p className="error">Błąd: {error}</p> : null}

					{isBtn && (
						<button className="btn clone" onClick={submit}>
							{status ? "Klonuj więcej (wybierz z listy)" : guilds.length > 0 ? "Zacznij klonować" : "Przejdź dalej"}
						</button>
					)}

					<p
						className={`${
							lengthServer && lengthServer.lengthServer === lengthServer.maxServer ? "block" : "hidden"
						} center`}>
						Jestes na maksymalnej ilości serwerów, wyjdz z jakiegoś serwera i wróc ponownie.{" "}
						<span className="max-limit">(Lub odśwież stronę)</span>
					</p>
				</form>
			</div>
		</>
	)
}

const Wrapper = () => {
	return (
		<>
			<Popup />
			<ClonerDiscord />
			<Loading />
		</>
	)
}

export default Wrapper
